// vendors
import React, { useEffect, useState } from 'react';
import {
  Grid,
  Row,
  Column,
  Pagination,
  Tile,
  Button,
} from 'carbon-components-react';

import { useHistory, useLocation } from 'react-router-dom';
import TicketList from '../../components/TicketList';
import FilterTree from '../../components/FilterTree';
import TicketsMap from '../../components/TicketsMap';
import { useZohoDesk } from '../../contexts/ZohoDesk';

const filterDefaultValues = {
  status: {
    title: 'Status',
    items: [
      {
        id: 'Open',
        labelText: 'Open',
      },
      {
        id: 'On hold',
        labelText: 'On hold',
      },
      {
        id: 'Closed',
        labelText: 'Close',
      },
    ],
    isLimited: false,
  },
  priority: {
    title: 'Priority',
    items: [
      { id: 'High', labelText: 'High' },
      { id: 'Medium', labelText: 'Medium' },
      { id: 'Low', labelText: 'Low' },
      { id: 'null', labelText: 'None' },
    ],
    isLimited: false,
  },
  assignee: {
    title: 'Agent',
    items: [],
    isLimited: true,
    isLoading: false,
  },
};

const Dashboard = () => {
  const [state, setState] = useState({
    tickets: {
      data: [],
      isLoading: false,
    },
    filters: {
      data: filterDefaultValues,
      selected: {
        status: ['Open'],
      },
    },
    pagination: {
      page: 1,
      pageSize: 10,
    },
  });
  const zohoDesk = useZohoDesk();
  const history = useHistory();
  const location = useLocation();

  const setSearchParams = (params = {}) => {
    const { pathname, search } = location;

    const searchParams = new URLSearchParams(search);

    Object.keys(params).forEach((key) => {
      if (params[key]) {
        searchParams.set(key, params[key]);
      } else {
        searchParams.delete(key);
      }
    });

    history.push(`${pathname}?${searchParams.toString()}`);
  };

  useEffect(() => {
    if (zohoDesk.isReady) {
      const getTickets = async () => {
        setState((prev) => ({
          ...prev,
          tickets: {
            data: [],
            isLoading: true,
          },
        }));

        const opts = state.filters.selected;

        let data = await zohoDesk.getTickets(opts);

        data = await Promise.all(
          data.map(async (ticket) => {
            if (!ticket.assigneeId) return { ...ticket, assigneeName: '' };

            const agent = await zohoDesk.getAgent(ticket.assigneeId);

            return {
              ...ticket,
              assigneeName: agent.name || '',
            };
          })
        );

        setState((prev) => ({
          ...prev,
          tickets: {
            data,
            isLoading: false,
          },
        }));
      };

      getTickets();
    }
  }, [zohoDesk, zohoDesk.isReady, state.filters.selected]);

  useEffect(() => {
    if (zohoDesk.isReady) {
      const getAgents = async () => {
        setState((prev) => ({
          ...prev,
          filters: {
            ...prev.filters,
            data: {
              ...prev.filters.data,
              assignee: {
                ...prev.filters.data.assignee,
                isLoading: true,
              },
            },
          },
        }));

        const agents = await zohoDesk.getAgents();

        const items = agents.map((agent) => ({
          id: agent.id,
          labelText: `${agent.firstName} ${agent.lastName}`,
        }));

        setState((prev) => ({
          ...prev,
          filters: {
            ...prev.filters,
            data: {
              ...prev.filters.data,
              assignee: {
                ...prev.filters.data.assignee,
                isLoading: false,
                items,
              },
            },
          },
        }));
      };

      getAgents();
    }
  }, [zohoDesk, zohoDesk.isReady]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    const page = searchParams.get('page');
    const pageSize = searchParams.get('pageSize');
    const queryParamsfilters = searchParams.get('filter');

    const filterSelected = queryParamsfilters
      ? queryParamsfilters.split(',').reduce((acc, cur) => {
          const [key, values] = cur.split('=');

          acc[key] = values.split('|');

          return acc;
        }, {})
      : {};

    setState((prev) => {
      const filtersHasChanged =
        Object.keys(prev.filters.selected).length !==
          Object.keys(filterSelected).length ||
        !Object.keys(filterSelected).every(
          (filterKey) =>
            prev.filters.selected[filterKey].length ===
            filterSelected[filterKey].length
        );

      return {
        ...prev,
        pagination: {
          page: Number(page) || 1,
          pageSize: Number(pageSize) || 10,
        },
        ...(filtersHasChanged && {
          filters: {
            ...prev.filters,
            selected: filterSelected,
          },
        }),
      };
    });
  }, [location]);

  const handlePageChange = (data) => {
    setSearchParams({ page: data.page, pageSize: data.pageSize });
  };

  const handleFilterChange = (value, key) => {
    const [name, id] = key.split('.');

    let list;

    if (!value) {
      list = {
        ...state.filters.selected,
        [name]: (state.filters.selected[name] || []).filter((e) => e !== id),
      };
    } else {
      list = {
        ...state.filters.selected,
        [name]: [...(state.filters.selected[name] || []), id],
      };
    }

    const filters = Object.keys(list)
      .map((typeKey) => `${typeKey}=${list[typeKey].join('|')}`)
      .join(',');

    setSearchParams({ filter: filters, page: 1 });
  };

  const handleClearFilterByNameClick = (name) => {
    const list = { ...state.filters.selected };

    delete list[name];

    const filters = Object.keys(list)
      .map((typeKey) => `${typeKey}=${list[typeKey].join('|')}`)
      .join(',');

    setSearchParams({ filter: filters, page: 1 });
  };

  const handleResetFilters = () => {
    setSearchParams({ filter: null, page: 1 });
  };

  const selectedFiltersCount = Object.values(state.filters.selected).reduce(
    (acc, cur) => [...acc, ...cur],
    []
  ).length;

  return (
    <Grid narrow>
      <Row>
        <Column>
          <h2>Dashboard</h2>
        </Column>
      </Row>

      <Row>
        <Column>
          <TicketsMap tickets={state.tickets.data} />
        </Column>
      </Row>

      <Row>
        <Column lg={3}>
          <Tile>
            {selectedFiltersCount > 0 && (
              <Button kind='ghost' onClick={handleResetFilters}>
                Reset Filters
              </Button>
            )}

            {Object.keys(state.filters.data).map((key) => {
              const filter = state.filters.data[key];

              return (
                <FilterTree
                  title={filter.title}
                  name={key}
                  onChange={handleFilterChange}
                  filters={filter.items}
                  selectedFilters={state.filters.selected[key] || []}
                  isLimited={filter.isLimited}
                  onClearSelections={handleClearFilterByNameClick}
                  isLoading={filter.isLoading}
                />
              );
            })}
          </Tile>
        </Column>
        <Column lg={13}>
          <TicketList
            tickets={state.tickets.data}
            isLoading={state.tickets.isLoading}
            page={state.pagination.page}
            pageSize={state.pagination.pageSize}
          />

          <Pagination
            page={state.pagination.page}
            pageSize={state.pagination.pageSize}
            pageSizes={[10, 25, 50, 75, 100]}
            totalItems={state.tickets.data.length}
            onChange={handlePageChange}
          />
        </Column>
      </Row>
    </Grid>
  );
};

export default Dashboard;
