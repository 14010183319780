/* eslint-disable no-underscore-dangle */
import ApiClient from '../ApiClient';

export default class TicketsApi {
  constructor(apiClient) {
    this._apiClient = apiClient || ApiClient.instance;
  }

  async list(opts = {}) {
    const queryParams = {
      ...opts,
    };

    if (queryParams.from !== undefined && queryParams.limit !== undefined) {
      const { data } = await this._apiClient.callApi('tickets', {
        queryParams,
      });

      return data;
    }

    return this._apiClient.list('tickets', { queryParams });
  }
}
