import TicketsCountApi from './src/api/TicketsCountApi';
import TicketsApi from './src/api/TicketsApi';
import AgentsApi from './src/api/AgentsApi';
import ApiClient from './src/ApiClient';

export class ZohoDeskSDK {
  constructor() {
    if (!ZohoDeskSDK.instance) {
      this.apiClient = new ApiClient();

      this.agents = new AgentsApi(this.apiClient);
      this.tickets = new TicketsApi(this.apiClient);
      this.ticketsCount = new TicketsCountApi(this.apiClient);

      ZohoDeskSDK.instance = this;
    }

    return ZohoDeskSDK.instance;
  }

  config(opts) {
    if (!opts.accessToken) throw new Error('Zoho access Token is required');

    if (!opts.organisationId)
      throw new Error('Zoho organisation id is required');

    this.apiClient.organisationId = opts.organisationId;
    this.apiClient.accessToken = opts.accessToken;
  }
}

const zohoDeskSDK = new ZohoDeskSDK();

Object.freeze(zohoDeskSDK);

export { zohoDeskSDK };
