import React, { useState } from 'react';
import {
  Button,
  Checkbox,
  CheckboxSkeleton,
  Tag,
} from 'carbon-components-react';
import PropTypes from 'prop-types';

const FilterTree = ({
  filters,
  title,
  onChange,
  name,
  selectedFilters,
  onClearSelections,
  isLimited,
  isLoading,
}) => {
  const [limited, setLimited] = useState(true);

  const handleChange = (value, checkboxId) => onChange(value, checkboxId);

  const handleClear = () => onClearSelections(name);

  const handleToggleShowMore = () => setLimited((prev) => !prev);

  return (
    <fieldset className='bx--fieldset filter-tree'>
      <div className='filter-tree__header'>
        <legend className='bx--label filter-tree__title'>{title}</legend>
        {selectedFilters.length > 0 && (
          <Tag
            title='Clear Filter'
            filter
            onClick={handleClear}
            type='high-contrast'
          >
            {selectedFilters.length}
          </Tag>
        )}
      </div>

      {!isLoading &&
        filters
          .sort((a, b) => {
            const includeA = selectedFilters.includes(a.id);
            const includeB = selectedFilters.includes(b.id);

            if (includeA && !includeB) return -1;

            if (!includeA && includeB) return 1;

            return 0;
          })
          .slice(0, isLimited && limited ? 3 : filters.length)
          .map(({ id, labelText }) => (
            <Checkbox
              id={`${name}.${id}`}
              labelText={labelText}
              checked={selectedFilters.includes(id)}
              onChange={handleChange}
            />
          ))}

      {isLoading &&
        Array(3)
          .fill()
          .map(() => <CheckboxSkeleton />)}

      {isLimited && (
        <Button kind='ghost' onClick={handleToggleShowMore}>
          Show {limited ? 'more' : 'less'}...
        </Button>
      )}
    </fieldset>
  );
};

FilterTree.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      labelText: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedFilters: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onClearSelections: PropTypes.func,
  name: PropTypes.string.isRequired,
  isLimited: PropTypes.bool,
  isLoading: PropTypes.bool,
};

FilterTree.defaultProps = {
  selectedFilters: [],
  onChange: () => {},
  onClearSelections: () => {},
  isLimited: true,
  isLoading: false,
};

export default FilterTree;
