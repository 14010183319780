// vendors
import React from 'react';
import { Button } from 'carbon-components-react';
import styled from '@emotion/styled';
import { layout } from '@carbon/layout';

import { useHistory, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/Auth';

const Banner = styled.div`
  margin-top: ${layout[2]};
  margin-bottom: ${layout[2]};
`;

const Panel = styled.div`
  padding: ${layout[2]};
  margin-top: ${layout[3]};
  margin-bottom: ${layout[3]};
  background-color: white;
`;

const Login = () => {
  const { login } = useAuth();
  const history = useHistory();
  const location = useLocation();

  const { from } = location.state || { from: { pathname: '/' } };

  const handleLogin = async () => {
    await login();

    history.replace(from);
  };

  return (
    <div className='bx--grid theme--g100'>
      <div className='bx--row'>
        <Panel className='bx--offset-lg-4 bx--col-lg-8'>
          <Banner>
            <h2>Log in to Civil Maintenance Tickets Dashboard</h2>
          </Banner>

          <Button onClick={handleLogin}>Login</Button>
        </Panel>
      </div>
    </div>
  );
};

export default Login;
