import React from 'react';
import { red60, yellow, blue70 } from '@carbon/colors';
import PropTypes from 'prop-types';

import SiteList from '../../data/engineeringSites.json';

import Map from '../Map';

const getPriorityWeight = (priority) => {
  if (priority === 'Low') return 3;

  if (priority === 'Medium') return 2;

  if (priority === 'High') return 1;

  return 4;
};

const getColorByPriorityId = (priority) => {
  if (priority === 'Low') return blue70;

  if (priority === 'Medium') return yellow;

  if (priority === 'High') return red60;

  return blue70;
};

const TicketsMap = ({ tickets }) => {
  const ticketGroupBySite = tickets.reduce((acc, ticket) => {
    ticket.sites.forEach((site) => {
      if (!site) return;

      let index = acc.findIndex(
        ({ engineeringNumber }) => engineeringNumber === site.engineeringNumber
      );

      if (index < 0) {
        const value = SiteList.find(
          ({ engineeringNumber }) =>
            engineeringNumber === site.engineeringNumber
        );

        index =
          acc.push({
            engineeringNumber: site.engineeringNumber,
            name: (value || {}).name || undefined,
            coordinates: value ? [value.lng, value.lat] : undefined,
            tickets: [],
          }) - 1;
      }

      acc[index].tickets.push({
        id: ticket.id,
        ticketNumber: ticket.ticketNumber,
        priority: ticket.priority,
        subject: ticket.subject,
        webUrl: ticket.webUrl,
      });
    });

    return acc;
  }, []);

  const markers = ticketGroupBySite
    .map((site) => {
      return {
        coordinates: site.coordinates,
        color: getColorByPriorityId(site.tickets[0].priority),
        body: `<div>
          <p>${site.name}</p>
          <ul>
            ${site.tickets
              .sort((a, b) => {
                const aWeight = getPriorityWeight(a.priority);
                const bWeight = getPriorityWeight(b.priority);

                return aWeight - bWeight;
              })
              .map(
                (ticket) =>
                  `<li><a href="${ticket.webUrl}" target="_blank">${ticket.subject}</a></li>`
              )}
          </ul>
        </div>`,
      };
    })
    .filter(({ coordinates }) => typeof coordinates === 'object');

  return <Map className='tickets-map' markers={markers} />;
};

TicketsMap.propTypes = {
  tickets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      ticketNumber: PropTypes.string.isRequired,
      priority: PropTypes.string,
      subject: PropTypes.string.isRequired,
      webUrl: PropTypes.string.isRequired,
      sites: PropTypes.arrayOf(
        PropTypes.shape({ engineeringNumber: PropTypes.string.isRequired })
      ).isRequired,
    })
  ).isRequired,
};

export default TicketsMap;
