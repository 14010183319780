import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

export const AuthContext = React.createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({
  children,
  onRedirectCallback,
  redirectUri,
  clientId,
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);
  const [token, setToken] = useState(null);

  const params = {
    clientId,
    scope:
      'Desk.tickets.READ,Desk.basic.READ,Desk.settings.READ,Desk.search.READ',
    redirectUri,
  };

  const url = `https://accounts.zoho.com/oauth/v2/auth?scope=${params.scope}&client_id=${params.clientId}&response_type=token&redirect_uri=${params.redirectUri}/oauthredirect/`;

  useEffect(() => {
    const initAuth = async () => {
      if (
        window.location.hash.includes('access_token=') &&
        window.location.hash.includes('location=') &&
        window.location.hash.includes('expires_in=')
      ) {
        const response = {};

        decodeURIComponent(window.location.hash)
          .substr(2)
          .split('&')
          .forEach((string) => {
            const [key, value] = string.split('=');

            response[key] = value;
          });

        const expiresIn = response.expires_in
          ? parseInt(response.expires_in, 10)
          : NaN;

        window.localStorage.setItem(
          'authentification',
          JSON.stringify({
            token: response.access_token,
            expiresAt: !Number.isNaN(expiresIn) ? Date.now() + expiresIn : null,
          })
          // ? Date.now() + expiresIn * 1000
        );

        if (window.opener) {
          window.opener.postMessage(response);
        } else {
          onRedirectCallback();
        }

        setToken(response.access_token);

        setIsAuthenticated(true);
      } else if (window.localStorage.getItem('authentification')) {
        const data = JSON.parse(
          window.localStorage.getItem('authentification')
        );

        setToken(data.token);

        setIsAuthenticated(Date.now() <= data.expiresAt);
      }

      setLoading(false);
    };

    initAuth();
    // eslint-disable-next-line
  }, []);

  const runPopup = (popup) => {
    return new Promise((resolve, reject) => {
      const timeoutId = setTimeout(() => {
        reject(popup);
      }, 60 * 1000);

      window.addEventListener('message', (e) => {
        if (e.data.source === 'react-devtools-bridge') return;

        if (!e.data || !e.data.access_token) {
          reject();

          return;
        }

        clearTimeout(timeoutId);

        popup.close();

        resolve(e.data);
      });
    });
  };

  const login = async () => {
    setPopupOpen(true);

    const popup = window.open(
      url,
      'Authentification',
      'scrollbars=no,toolbar=no,location=no,titlebar=no,directories=no,status=no,menubar=no'
    );

    const result = await runPopup(popup);

    if (result.access_token) {
      setToken(result.access_token);

      setIsAuthenticated(true);
    }

    setPopupOpen(false);
  };

  const logout = async () => {
    window.localStorage.removeItem('authentification');

    setIsAuthenticated(false);

    // onRedirectCallback();

    // TODO: Add redirection
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    setLoading(false);
  };

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        handleRedirectCallback,
        loading,
        isAuthenticated,
        popupOpen,
        token,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
  onRedirectCallback: PropTypes.func,
  redirectUri: PropTypes.string,
  clientId: PropTypes.string,
};

AuthProvider.defaultProps = {
  onRedirectCallback: DEFAULT_REDIRECT_CALLBACK,
  redirectUri: process.env.REACT_APP_URL,
  clientId: process.env.REACT_APP_ZOHO_CLIENT_ID,
};
