import React from 'react';
import { Route } from 'react-router-dom';
import { Content } from 'carbon-components-react';
import styled from '@emotion/styled';

import Login from './scenes/Login';
import Header from './components/Header';
import Dashboard from './scenes/Dashboard/Dashboard';

import './app.scss';
import { useAuth } from './contexts/Auth';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';

const StyledContent = styled(Content)`
  background: none;
  background-color: none;
`;

function App() {
  const { loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Header />

      <StyledContent>
        <Route path='/login' component={Login} />

        <PrivateRoute path='/' exact>
          <Dashboard />
        </PrivateRoute>
      </StyledContent>
    </div>
  );
}

export default App;
