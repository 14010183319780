import React, { useState } from 'react';
import {
  DataTable,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableHeader,
  TableToolbar,
  TableToolbarContent,
  TableToolbarMenu,
  Checkbox,
  Link,
  Tooltip,
  DataTableSkeleton,
} from 'carbon-components-react';
import PropTypes from 'prop-types';

const convertPriorityIdToName = (priority) => {
  if (priority === 'Low') return '3 - Low';

  if (priority === 'Medium') return '2 - Medium';

  if (priority === 'High') return '1 - High';

  return '4 - None';
};

const dataTableHeadersDefaultValue = [
  {
    header: 'Ticket ID',
    key: 'ticketId',
    visible: true,
  },
  {
    header: 'Site',
    key: 'engineeringNumber',
    visible: true,
  },
  {
    header: 'Subject',
    key: 'subject',
    visible: true,
  },
  {
    header: 'Category',
    key: 'category',
    visible: true,
  },
  {
    header: 'Sub category',
    key: 'subCategory',
    visible: true,
  },
  {
    header: 'Priority',
    key: 'priority',
    visible: true,
  },
  {
    header: 'Assignee',
    key: 'assigneeName',
    visible: true,
  },
  {
    header: 'Status',
    key: 'status',
    visible: true,
  },
  {
    header: 'Creation date',
    key: 'createdTime',
    visible: true,
  },
];

const TicketList = ({ tickets, page, pageSize, isLoading }) => {
  const [columns, setColumns] = useState(dataTableHeadersDefaultValue);

  const first = (page - 1) * pageSize;
  const last = first + pageSize;

  const handleColumnChange = (value, id) => {
    const list = columns.map((column) => {
      if (column.key === id) {
        return {
          ...column,
          visible: value,
        };
      }

      return { ...column };
    });

    setColumns(list);
  };

  const dataTableHeaders = columns.filter(({ visible }) => visible);

  const options = {
    year: 'numeric',
    month: '2-digit',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  };

  const dataTableRows = tickets.map((ticket) => {
    const createdTime = new Date(ticket.createdTime);

    return {
      ...ticket,
      createdTime: createdTime.toLocaleString('en-CA', options),
      ticketId: ticket.ticketNumber,
      subject: (
        <Link href={ticket.webUrl} target='_blank' rel='noopener noreferrer'>
          {ticket.subject}
        </Link>
      ),
      priority: convertPriorityIdToName(ticket.priority),
      engineeringNumber: (
        <ul>
          {ticket.sites.map(({ engineeringNumber, name }) => (
            <li key={engineeringNumber}>
              <span>{engineeringNumber}</span>

              {!name && <Tooltip>Site no found in the database.</Tooltip>}
            </li>
          ))}
        </ul>
      ),
    };
  });

  return (
    <DataTable
      isSortable
      headers={dataTableHeaders}
      rows={dataTableRows}
      render={({ rows, headers, getHeaderProps }) => (
        <TableContainer>
          <TableToolbar>
            <TableToolbarContent>
              <TableToolbarMenu>
                {columns.map((column) => (
                  <Checkbox
                    id={column.key}
                    labelText={column.header}
                    checked={column.visible}
                    onChange={handleColumnChange}
                  />
                ))}
              </TableToolbarMenu>
            </TableToolbarContent>
          </TableToolbar>

          {isLoading && (
            <DataTableSkeleton
              headers={columns.map((column) => ({ key: column.header }))}
              showHeader={false}
              showToolbar={false}
              rowCount={pageSize}
            />
          )}

          {!isLoading && (
            <Table>
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    <TableHeader {...getHeaderProps({ header })}>
                      {header.header}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {rows.slice(first, last).map((row) => (
                  <TableRow key={row.id}>
                    {row.cells.map((cell) => (
                      <TableCell key={cell.id}>{cell.value}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </TableContainer>
      )}
    />
  );
};

TicketList.propTypes = {
  tickets: PropTypes.arrayOf(
    PropTypes.shape({
      createdTime: PropTypes.string.isRequired,
      ticketNumber: PropTypes.string.isRequired,
      webUrl: PropTypes.string.isRequired,
      subject: PropTypes.string.isRequired,
      priority: PropTypes.string,
      sites: PropTypes.arrayOf(
        PropTypes.shape({
          engineeringNumber: PropTypes.string.isRequired,
          name: PropTypes.string,
        })
      ).isRequired,
      category: PropTypes.string,
      subCategory: PropTypes.string,
      assigneeName: PropTypes.string,
      status: PropTypes.string.isRequired,
    })
  ).isRequired,
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  isLoading: PropTypes.bool,
};

TicketList.defaultProps = {
  isLoading: false,
};

export default TicketList;
