/* eslint-disable no-underscore-dangle */
import ApiClient from '../ApiClient';

export default class AgentsApi {
  constructor(apiClient) {
    this._apiClient = apiClient || ApiClient.instance;
  }

  async get(id) {
    return this._apiClient.callApi(`agents/${id}`);
  }

  async list(opts = {}) {
    const queryParams = {
      ...opts,
    };
    return this._apiClient.list('agents', { queryParams });
  }
}
