import React, { useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import PropTypes from 'prop-types';

const Map = ({ markers = [], ...rest }) => {
  const mapContainer = useRef();
  const map = useRef(null);
  const markersRef = useRef([]);

  useEffect(() => {
    const initMap = () => {
      if (!map.current) {
        mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

        map.current = new mapboxgl.Map({
          container: mapContainer.current,
          style: 'mapbox://styles/mapbox/streets-v11',
          center: [-99.84375, 56.165129], // [lng, lat]
          zoom: 3,
        });
      }
    };

    initMap();
  }, [map, mapContainer]);

  useEffect(() => {
    if (map.current) {
      markersRef.current.map((marker) => marker.remove());

      markersRef.current = markers.map((marker) => {
        const popup = new mapboxgl.Popup().setHTML(marker.body);

        const markerOptions = {};

        if (marker.color) markerOptions.color = marker.color;

        return new mapboxgl.Marker(markerOptions)
          .setLngLat(marker.coordinates)
          .setPopup(popup)
          .addTo(map.current);
      });
    }
  }, [map, markers, markersRef]);

  return <div ref={mapContainer} {...rest} />;
};

Map.propTypes = {
  markers: PropTypes.arrayOf(
    PropTypes.shape({
      body: PropTypes.string.isRequired,
      coordinates: PropTypes.arrayOf(PropTypes.number).isRequired,
      color: PropTypes.string,
    })
  ),
};

Map.defaultProps = {
  markers: [],
};

export default Map;
