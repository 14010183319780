/* eslint-disable no-underscore-dangle */
import ApiClient from '../ApiClient';

export default class TicketsCountApi {
  constructor(apiClient) {
    this._apiClient = apiClient || ApiClient.instance;
  }

  async get(opts = {}) {
    const queryParams = {
      ...opts,
    };

    const { count = 0 } = await this._apiClient.callApi('ticketsCount', {
      queryParams,
    });

    return count;
  }
}
