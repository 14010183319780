// vendors
import React from 'react';
import {
  HeaderContainer,
  Header,
  HeaderName,
  HeaderGlobalBar,
  HeaderGlobalAction,
} from 'carbon-components-react';

import { Logout20, Login20 } from '@carbon/icons-react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../contexts/Auth';

const AppHeader = () => {
  const { isAuthenticated, login, logout } = useAuth();
  const history = useHistory();

  const handleLogout = () => {
    logout();

    history.push('/');
  };

  return (
    <HeaderContainer
      render={() => (
        <>
          <Header aria-label='MCM Support Dashboard'>
            <HeaderName href='/' prefix='MCM Support Dashboard'>
              [Bell]
            </HeaderName>

            <HeaderGlobalBar>
              {!isAuthenticated && (
                <HeaderGlobalAction aria-label='Login' onClick={() => login()}>
                  <Login20 />
                </HeaderGlobalAction>
              )}

              {isAuthenticated && (
                <HeaderGlobalAction aria-label='Logout' onClick={handleLogout}>
                  <Logout20 />
                </HeaderGlobalAction>
              )}
            </HeaderGlobalBar>
          </Header>
        </>
      )}
    />
  );
};

export default AppHeader;
