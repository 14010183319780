import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { useAuth } from './Auth';
import { zohoDeskSDK } from '../api/Zoho-desk-sdk';
import SiteList from '../data/engineeringSites.json';

export const ZohoDeskContext = React.createContext();

export const useZohoDesk = () => useContext(ZohoDeskContext);

export const ZohoDeskProvider = ({ children }) => {
  const { token, isAuthenticated } = useAuth();
  const dataValues = useRef({
    agents: [],
  });

  const [isReady, setIsReady] = useState(false);

  const tokenize = useCallback(() => {
    if (isAuthenticated) {
      zohoDeskSDK.config({
        accessToken: token,
        organisationId: 682245720,
      });

      setIsReady(true);
    }
  }, [token, isAuthenticated]);

  useEffect(tokenize, [tokenize, isAuthenticated]);

  const getTicketCount = async (opts) => {
    if (!isReady) return 0;

    const count = await zohoDeskSDK.ticketsCount.get(opts);

    return count;
  };

  const getTickets = async (opts) => {
    if (!isReady) return [];

    let data = [];

    if (Object.keys(opts).length > 0) {
      data = await zohoDeskSDK.tickets.list({ ...opts });
    } else {
      const count = await getTicketCount(opts);

      data = await Promise.all(
        Array(Math.ceil(count / 100))
          .fill()
          .map(async (_, index) => {
            const res = await zohoDeskSDK.tickets.list({
              ...opts,
              limit: 100,
              from: index * 100,
            });

            return res;
          })
      ).then((res) => res.reduce((acc, cur) => [...acc, ...cur], []));
    }

    return data.map((item) => {
      const engineeringNumbers = item.subject.match(/\w\d{4}/g) || [];

      const sites = (engineeringNumbers || [])
        .map((e) => {
          const site = SiteList.find(
            ({ engineeringNumber }) => engineeringNumber === e
          );
          return {
            engineeringNumber: e,
            name: (site || {}).name || undefined,
            coordinates: site ? [site.lng, site.lat] : undefined,
          };
        })
        .filter((e) => e);

      return {
        ...item,
        ticketNumber: item.ticketNumber || null,
        sites,
      };
    });
  };

  const getAgents = async (opts) => {
    if (!isReady) return [];

    const agents = await zohoDeskSDK.agents.list(opts);

    dataValues.current = {
      ...dataValues,
      agents,
    };

    return agents;
  };

  const getAgent = async (id, opts = {}) => {
    const agent = dataValues.current.agents.find((e) => e.id === id);

    if (agent) return { ...agent };

    const data = await zohoDeskSDK.agents.get(id, opts);

    dataValues.current.agents.push(data);

    return { ...data };
  };

  return (
    <ZohoDeskContext.Provider
      value={{ getAgents, getAgent, getTickets, getTicketCount, isReady }}
    >
      {children}
    </ZohoDeskContext.Provider>
  );
};

ZohoDeskProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
