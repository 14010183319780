class ApiClient {
  constructor() {
    if (!ApiClient.instance) {
      ApiClient.instance = this;
    }

    return ApiClient.instance;
  }

  async callApi(path, opts = {}) {
    const {
      queryParams = {},
      // method = 'GET',
    } = opts;

    const url = new URL(`/api/v1/${path}`, 'https://desk.zoho.com');

    Object.keys(queryParams || {}).forEach((key) => {
      url.searchParams.set(key, queryParams[key]);
    });

    return new Promise((resolve, reject) => {
      try {
        fetch(url, {
          headers: {
            Authorization: `Bearer ${this.accessToken}`,
            orgId: this.organisationId,
          },
        })
          .then((res) => res.json())
          .then((data) => resolve(data));
      } catch (error) {
        reject(error);
      }
    });
  }

  async list(path, opts = {}) {
    const { queryParams } = opts;

    queryParams.limit = queryParams.limit || 100;
    queryParams.from = queryParams.from || 0;

    let data = [];

    const url = new URL(`/api/v1/${path}`, 'https://desk.zoho.com');

    Object.keys(queryParams || {}).forEach((key) => {
      url.searchParams.set(key, queryParams[key]);
    });

    const res = await fetch(url, {
      headers: {
        Authorization: `Bearer ${this.accessToken}`,
        orgId: this.organisationId,
      },
    });

    if (!res.ok) throw new Error('Network response was not ok');

    if (res.status === 200) {
      const body = await res.json();

      const loopData = await this.list(path, {
        ...opts,
        queryParams: {
          ...queryParams,
          from: queryParams.from + queryParams.limit,
        },
      });

      data = [...body.data, ...loopData];
    }

    return data;
  }
}

export default () => {
  const instance = new ApiClient();

  // Object.freeze(instance);

  return instance;
};
