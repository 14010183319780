import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from './contexts/Auth';

import * as serviceWorker from './serviceWorker';

import App from './App';

import './index.scss';
import { ZohoDeskProvider } from './contexts/ZohoDesk';

ReactDOM.render(
  <Router>
    <AuthProvider
      redirectUri={process.env.REACT_APP_URL}
      clientId={process.env.REACT_APP_ZOHO_CLIENT_ID}
    >
      <ZohoDeskProvider>
        <App />
      </ZohoDeskProvider>
    </AuthProvider>
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
